@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

.bg-blue {
  background-color: #1E396C;
}

.bg-orange {
  background-color: #F26A2A;
}

.btn-blue {
  background-color: #1E396C;
}

.btn-blue:hover {
  background-color: #15284b;
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.btn-orange {
  border-radius: 8px;
  background-color: #F26A2A;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  user-select: none;
  cursor: pointer;
  color: #fff;
}

.btn-orange:hover {
  background-color: #cc5a25;
}


.rad-5px {
  border-radius: 5px;
}

.rad-7px {
  border-radius: 7px;
}

.real {
  position: absolute;
  top: 183px;
  left: 320px;
}

.garis-real {
  position: absolute;
  top: 245px;
  left: 320px;
}

.shadow-md {
  box-shadow: 0px 4px 20px 5px #0000001A;

}

.judul-biru-20px {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: #1E396C;
}

.isi-biru-14px {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #1E396C;
}

.gambar-depan {
  position: absolute;
  width: 414px;
  top: 40px;
  right: 170px;
  z-index: -1;
}

.garisbawah {
  margin-bottom: -90px;
}

.BG-Apartmen1 {


  position: relative;
  z-index: 1;

}

.BG-Apartmen1::before {

  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.01) 91%, rgb(255, 254, 254) 100%), url('/public/image/Apartmen1.png');
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: -1;

}

@media screen and (max-width: 450px) {
  .real {
    top: 135px;
    left: 180px;
  }

  .garis-real {

    top: 195px;
    left: 180px;
  }
}